import type { PartialRecursive } from '../../abstract/_';
import { AbstractCollection } from '../../abstract/Collection';
import { AbstractDocument, Identifiable, Timestampable } from '../../abstract/Document';
import { type Supplier, Suppliers } from '../Suppliers';
import type { IFile } from './Files.types';
import { mergeDeepRight } from 'ramda';

@Identifiable
@Timestampable
export class File extends AbstractDocument<IFile> {
  readonly collections = {};

  readonly translatable = (data: IFile): IFile['_translations'][string] => ({
    name: data.name,
  });
}

export class Files extends AbstractCollection<File, IFile> {
  static definitions = {
    _: {} as IFile,
  };

  static path = 'files';

  constructor(document: Supplier) {
    super(document.collection(Files.path), File);
  }

  static new<M extends typeof Files.definitions, K extends keyof M>(key: K, value?: PartialRecursive<M[K]>) {
    let result: PartialRecursive<IFile> = {};

    if (key !== '_' && key in Files.definitions) {
      result = (result[key as keyof Omit<typeof Files.definitions, '_'>] as PartialRecursive<M[K]>) || {};
    }

    if (value != null) {
      result = mergeDeepRight(result, value) as PartialRecursive<M[K]>;
    }

    return result as M[K];
  }

  get supplier() {
    return Suppliers._.getById(this.reference.parent.id);
  }
}
