import type { PartialRecursive } from '../../abstract/_';
import { AbstractCollection } from '../../abstract/Collection';
import { AbstractDocument, Identifiable, Timestampable } from '../../abstract/Document';
import { type Supplier, Suppliers } from '../Suppliers';
import type { IFair } from './Fairs.types';
import { mergeDeepRight } from 'ramda';

@Identifiable
@Timestampable
export class Fair extends AbstractDocument<IFair> {
  readonly collections = {};

  readonly translatable = (data: IFair): IFair['_translations'][string] => ({
    description: data.description,
    name: data.name,
  });
}

export class Fairs extends AbstractCollection<Fair, IFair> {
  static definitions = {
    _: {} as IFair,
  };

  static path = 'fairs';

  constructor(document: Supplier) {
    super(document.collection(Fairs.path), Fair);
  }

  static new<M extends typeof Fairs.definitions, K extends keyof M>(key: K, value?: PartialRecursive<M[K]>) {
    let result: PartialRecursive<IFair> = {};

    if (key !== '_' && key in Fairs.definitions) {
      result = (result[key as keyof Omit<typeof Fairs.definitions, '_'>] as PartialRecursive<M[K]>) || {};
    }

    if (value != null) {
      result = mergeDeepRight(result, value) as PartialRecursive<M[K]>;
    }

    return result as M[K];
  }

  get supplier() {
    return Suppliers._.getById(this.reference.parent.id);
  }
}
