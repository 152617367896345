import type { PartialRecursive } from '../abstract/_';
import { AbstractCollection } from '../abstract/Collection';
import { AbstractDocument, Identifiable, Timestampable } from '../abstract/Document';
import type { ISupplier, ISupplier_Type } from './Suppliers.types';
import { Achievements } from './Suppliers/Achievements';
import { Admins } from './Suppliers/Admin';
import { Ads } from './Suppliers/Ads';
import { Apps } from './Suppliers/Apps';
import { Badges } from './Suppliers/Badges';
import { Brochures } from './Suppliers/Brochures';
import { Contacts } from './Suppliers/Contacts';
import { Fairs } from './Suppliers/Fairs';
import { FeedbackCollection } from './Suppliers/Feedback';
import { Files } from './Suppliers/Files';
import { FollowUps } from './Suppliers/FollowUps';
import { Offers } from './Suppliers/Offers';
import { Packages } from './Suppliers/Packages';
import { Photos } from './Suppliers/Photos';
import { Questions } from './Suppliers/Questions';
import { Recommendations } from './Suppliers/Recommendations';
import { Relations } from './Suppliers/Relations';
import { Reports } from './Suppliers/Reports';
import { Templates } from './Suppliers/Templates';
import { Users } from './Suppliers/Users';
import { Videos } from './Suppliers/Videos';
import { Weddings } from './Suppliers/Weddings';
import { Works } from './Suppliers/Works';
import { mergeDeepRight } from 'ramda';

@Identifiable
@Timestampable
export class Supplier extends AbstractDocument<ISupplier> {
  readonly collections = {
    Achievements: new Achievements(this),
    Admins: new Admins(this),
    Ads: new Ads(this),
    Apps: new Apps(this),
    Badges: new Badges(this),
    Brochures: new Brochures(this),
    Contacts: new Contacts(this),
    Fairs: new Fairs(this),
    Feedback: new FeedbackCollection(this),
    Files: new Files(this),
    FollowUps: new FollowUps(this),
    Offers: new Offers(this),
    Packages: new Packages(this),
    Photos: new Photos(this),
    Questions: new Questions(this),
    Recommendations: new Recommendations(this),
    Relations: new Relations(this),
    Reports: new Reports(this),
    Templates: new Templates(this),
    Users: new Users(this),
    Videos: new Videos(this),
    Weddings: new Weddings(this),
    Works: new Works(this),
  };

  readonly translatable = (data: ISupplier): ISupplier['_translations'][string] => ({
    company: {
      manager: {
        welcomeMessage: data.company?.manager?.welcomeMessage,
      },
    },
    description: data.description,
    directions: data.directions,
    status: {
      message: data.status?.message,
    },
    bridebook: {
      offer: data.bridebook?.offer,
    },
  });

  /**
   * Get `admin` document from `admins` sub-collection.
   */
  getAdmin() {
    return this.collections.Admins.getAdmin();
  }

  /**
   * Get `chargebee` document from `admins` sub-collection.
   */
  getChargebee() {
    return this.collections.Admins.getChargebee();
  }

  /**
   * Get `ledger` document from `admins` sub-collection.
   */
  getLedger() {
    return this.collections.Admins.getLedger();
  }

  /**
   * Get `score` document from `admins` sub-collection.
   */
  getScore() {
    return this.collections.Admins.getScore();
  }

  /**
   * Get `stats` document from `admins` sub-collection.
   */
  getStats() {
    return this.collections.Admins.getStats();
  }

  /**
   * Get `stripe` document from `admins` sub-collection.
   */
  getStripe() {
    return this.collections.Admins.getStripe();
  }

  /**
   * Get first photo ordered by `order` prop
   */
  getThumbnail() {
    return this.collections.Photos.getFirst();
  }

  async getStructuredData() {
    const data = await this.get(true);
    let result: any = {
      '@context': 'https://schema.org',
      '@type': 'LocalBusiness',
    };

    result.address = {
      '@type': 'PostalAddress',
      addressCountry: data.address.country,
      addressLocality: data.address.city,
      addressRegion: data.address.adminArea[0],
      postalCode: data.address.postalCode,
    };

    if (data.flags?.hideStreet !== true) {
      result.address.streetAddress = data.address.street.join(', ');
    }

    if (data.counters.reviews > 0) {
      result = {
        ...result,
        ...(await this.collections.Feedback.getStructuredData(250, ['review'])),
      };
    }

    if (data.additionalAreas == null || data.additionalAreas.length === 0) {
      result.areaServed = data.address.adminArea;
    } else {
      result.areaServed = [...new Set([data.address.adminArea[0], ...data.additionalAreas])].join(', ');
    }

    if (data.company?.manager?.name != null) {
      result.contactPoint = {
        '@type': 'ContactPoint',
        contactType: 'Manager',
        name: data.company.manager.name,
      };
    }

    if (data.l10n.currency != null) {
      result.currenciesAccepted = data.l10n.currency;
    }

    if (data.description?.short != null) {
      result.description = data.description.short.trim();
    }

    if (data.contacts.email != null) {
      result.email = data.contacts.email;
    }

    if (typeof data.company.established != null) {
      result.foundingDate = data.company.established.toString();
    }

    if (data.coordinates != null) {
      result.geo = {
        '@type': 'GeoCoordinates',
        latitude: parseFloat(data.coordinates.latitude.toFixed(4)),
        longitude: parseFloat(data.coordinates.longitude.toFixed(4)),
      };
    }

    result.name = data.name.trim();

    /**
     * We currently don't have an explitic logo for suppliers.
     */
    result.logo = 'https://bridebook.imgix.net/assets/supplier/img-custom-venue.jpg';

    /**
     * We should use the most specific LocalBusiness sub-type.
     */
    if (data.type.includes('venue') === true) {
      const details = data.typeDetails?.venue;

      if (details?.time != null) {
        const pattern = /^(?<H>[0-9]+)[:](?<M>[0-9]+)[ ]*(?<D>[AP]M)$/;

        for (const key of ['usualStart', 'usualFinish'] as const) {
          let value = details.time[key];

          if (value != null) {
            const matches = value.match(pattern);

            if (matches != null) {
              matches.groups.H = parseInt(matches.groups.H, 10).toString();
              matches.groups.M = parseInt(matches.groups.M, 10).toString();

              /**
               * 12 AM means midnight, 12 PM means noon.
               */
              if (matches.groups.H === '12') {
                matches.groups.H = '0';
              }

              if (matches.groups.D === 'PM') {
                matches.groups.H = (parseInt(matches.groups.H, 10) + 12).toString();
              }

              value = `${matches.groups.H.padStart(2, '0')}:${matches.groups.M.padStart(2, '0')}`;
            } else {
              value = null;
            }
          }

          details.time[key] = value;
        }

        if (details.time.usualStart != null && details.time.usualFinish != null) {
          result.openingHours = [`Mo-Su ${details.time.usualStart}-${details.time.usualFinish}`];
        }

        if (details.time.usualStart != null || details.time.usualFinish != null) {
          result.openingHoursSpecification = {
            '@type': 'OpeningHoursSpecification',
            dayOfWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
          };

          if (details.time.usualStart != null) {
            result.openingHoursSpecification.opens = `${details.time.usualStart}:00`;
          }

          if (details.time.usualFinish != null) {
            result.openingHoursSpecification.closes = `${details.time.usualFinish}:00`;
          }
        }
      }
    }

    if (data.counters.photos > 0) {
      const photo = await this.collections.Photos.getFirst();

      /**
       * Google prefers this one.
       */
      result.image = `https://bridebook.imgix.net/${photo.path}?dpr=1&auto=format%2Ccompress%2Cenhance&fm=pjpg&crop=faces&fit=crop`;

      result.photo = {
        '@type': 'Photograph',
        url: `https://bridebook.imgix.net/${photo.path}?dpr=1&auto=format%2Ccompress%2Cenhance&fm=pjpg&crop=faces&fit=crop`,
      };
    }

    if (data.pricing?.indicator != null) {
      result.priceRange = data.pricing.indicator;
    }

    if (data.contacts.phone != null && data.contacts.phone.startsWith('+') === true) {
      result.telephone = data.contacts.phone;
    }

    result.url = this.getURL(data);

    /**
     * The property servesCuisine is not recognized by the schema (e.g. schema.org) for an object of type LocalBusiness.
     */
    // if (data.type.includes('catering') === true) {
    //   if ((data.typeDetails?.catering?.foodType != null) && (data.typeDetails.catering.foodType.length > 0)) {
    //     let servesCuisine = new Set();

    //     for (let foodType of data.typeDetails.catering.foodType) {
    //       switch (foodType) {
    //         case 'barCocktailService':
    //           servesCuisine.add('Bar & Cocktail');
    //         break;

    //         case 'caribbeanCuisine':
    //           servesCuisine.add('Caribbean');
    //         break;

    //         case 'chineseJapaneseCuisine':
    //           servesCuisine.add('Chinese / Japanese');
    //         break;

    //         case 'hogRoast':
    //           servesCuisine.add('Hog Roast');
    //         break;

    //         case 'latinSouthAmericanCuisine':
    //           servesCuisine.add('Latin / South-American');
    //         break;

    //         case 'middleEasternCuisine':
    //           servesCuisine.add('Middle Eastern');
    //         break;

    //         case 'westernEuropeanCuisine':
    //           servesCuisine.add('Western European');
    //         break;

    //         case 'other':
    //           servesCuisine.add('Other');
    //         break;
    //       }
    //     }

    //     if (data.typeDetails?.catering?.foodTypeOther != null) {
    //       servesCuisine.add(data.typeDetails.catering.foodTypeOther.trim());
    //     }

    //     if (servesCuisine.size > 0) {
    //       result.servesCuisine = [...servesCuisine].sort().join(', ');
    //     }
    //   }
    // }

    return result;
  }

  getURL(data: ISupplier, domain = 'https://bridebook.com') {
    let result = domain;
    // "group" type in not defined here because we don't want groups
    // to be available on the couple side
    const categories: Partial<Record<ISupplier_Type, string>> = {
      beauty: 'wedding-beauty-hair-makeup',
      cakes: 'wedding-cakes',
      catering: 'wedding-catering',
      decoration: 'wedding-decoration-hire',
      dress: 'wedding-dress-accessories',
      entertainment: 'wedding-entertainment',
      florist: 'wedding-florists',
      jewellery: 'wedding-rings-jewellery',
      marquee: 'wedding-marquee-hire',
      menswear: 'wedding-menswear',
      music: 'wedding-musicians-bands-djs',
      photo: 'wedding-photographers',
      planners: 'wedding-planners-toastmasters-celebrants',
      stationery: 'wedding-stationery',
      transport: 'wedding-transport',
      venue: 'wedding-venues',
      video: 'wedding-videographers',
    };

    result += '/' + (data.l10n.country === 'GB') ? 'uk' : data.l10n.country.toLowerCase();
    result += '/' + categories[data.type[0] as keyof typeof categories];
    result += '/' + data.name.toLowerCase().replace(/[^-0-9a-z]+/g, '-');
    result += '-' + data.address.city.toLowerCase().replace(/[^-0-9a-z]+/g, '-');
    result += '-' + data.address.adminArea[0].toLowerCase().replace(/[^-0-9a-z]+/g, '-');
    result += '-' + data.publicId;

    return result;
  }
}

export class Suppliers extends AbstractCollection<Supplier, ISupplier> {
  static definitions = {
    _: {} as ISupplier,
  };

  static path = 'suppliers';

  constructor() {
    super(Suppliers.path, Supplier);
  }

  static get _() {
    return new this();
  }

  static new<M extends typeof Suppliers.definitions, K extends keyof M>(key: K, value?: PartialRecursive<M[K]>) {
    let result: PartialRecursive<ISupplier> = {};

    if (key !== '_' && key in Suppliers.definitions) {
      result = (result[key as keyof Omit<typeof Suppliers.definitions, '_'>] as PartialRecursive<M[K]>) || {};
    }

    if (value != null) {
      result = mergeDeepRight(result, value) as PartialRecursive<M[K]>;
    }

    return result as M[K];
  }
}
