import { AbstractDocument, Timestampable } from '../../../abstract/Document';
import { type ISupplierLedgerTransaction, ISupplierProduct, ITransactionOperation } from '../Admin/ledger/Transactions.types';
import type { ISupplierAdminLedger } from './ledger.types';
import { Transactions } from './ledger/Transactions';
import {
  collection,
  doc,
  increment,
  orderBy,
  type PartialWithFieldValue,
  type QueryConstraint,
  runTransaction,
  serverTimestamp,
  where,
  type WithFieldValue,
} from 'firebase/firestore';
import uuid from 'uuid-random';

@Timestampable
export class AdminLedger extends AbstractDocument<ISupplierAdminLedger> {
  readonly collections = {
    Transactions: new Transactions(this),
  };

  /**
   * Returns the current ledger balance for the specificed product.
   *
   * @param product Product ID
   */
  async checkBalance(product: ISupplierProduct) {
    return (await this.get(true))?.balance?.[product] ?? 0;
  }

  /**
   * Creates a transaction and atomically updates the ledger balance for the respective `product`.
   * Throws if the `product` balance is not enough to subtract the amount of negative `units`.
   *
   * @param product Product ID
   * @param user User ID performing the Transaction
   * @param units Integer (Signed)
   * @param operation
   * @param metadata Transaction Metadata (Optional)
   */
  createTransaction(
    product: ISupplierProduct,
    user: string,
    units: number,
    operation?: ITransactionOperation,
    metadata?: ISupplierLedgerTransaction['metadata'],
  ) {
    return runTransaction(this.reference.firestore, (transaction) => {
      const ledgerRef = this.reference as any;
      const transactionRef = doc(collection(this.reference, 'transactions'), uuid());

      return transaction.get<ISupplierAdminLedger, ISupplierAdminLedger>(ledgerRef).then((ledger) => {
        const balance = ledger.data()?.balance?.[product] ?? 0;

        if (units < 0 && balance + units < 0) {
          throw new Error(`Insufficient balance for product '${product}'.`);
        }

        const ledgerData: PartialWithFieldValue<ISupplierAdminLedger> = {
          balance: {
            [product]: increment(units),
          },
          updatedAt: serverTimestamp(),
        };

        if (ledger.exists() !== true) {
          ledgerData.createdAt = serverTimestamp();
        }

        transaction.set<ISupplierAdminLedger, ISupplierAdminLedger>(ledgerRef, ledgerData, { merge: true });

        if (operation == null) {
          operation = units < 0 ? ITransactionOperation.SPENT : ITransactionOperation.PURCHASE;
        }

        const transactionData: WithFieldValue<ISupplierLedgerTransaction> = {
          balance: balance + units,
          createdAt: serverTimestamp(),
          id: transactionRef.id,
          operation,
          product,
          units,
          updatedAt: serverTimestamp(),
          user,
        };

        if (metadata != null && Object.keys(metadata).length > 0) {
          transactionData.metadata = metadata;
        }

        transaction.set(transactionRef, transactionData);

        return this.collections.Transactions.getById(transactionRef.id);
      });
    });
  }

  /**
   * Creates a new `purchase` transaction.
   *
   * @param product Product ID
   * @param user User ID performing the Transaction
   * @param units Units to Credit (Unsigned)
   * @param metadata Transaction Metadata (Optional)
   */
  purchase(product: ISupplierProduct, user: string, units = 1, metadata?: ISupplierLedgerTransaction['metadata']) {
    return this.createTransaction(product, user, Math.abs(units), ITransactionOperation.PURCHASE, metadata);
  }

  /**
   * Recalculates the ledger balance from the raw transactions.
   *
   * @param product Product ID (Optional)
   */
  async recalculateBalance(product?: string) {
    const result: Record<string, number> = {};
    const constraints: QueryConstraint[] = [orderBy('createdAt', 'asc')];

    if (product != null) {
      constraints.push(where('productId', '==', product));

      if (result[product] === undefined) {
        result[product] = 0;
      }
    }

    const transactions = await this.collections.Transactions.query(constraints).get(true);

    for (const key in transactions) {
      const transaction = transactions[key];

      if (result[transaction.product] === undefined) {
        result[transaction.product] = 0;
      }

      result[transaction.product] += transactions[key].units;
    }

    return product != null ? result[product] : result;
  }

  /**
   * Creates a new `spent` transaction.
   *
   * @param product Product ID
   * @param user User ID performing the Transaction
   * @param units Units to Debit (Unsigned)
   * @param metadata Transaction Metadata (Optional)
   */
  spend(product: ISupplierProduct, user: string, units = 1, metadata?: ISupplierLedgerTransaction['metadata']) {
    return this.createTransaction(product, user, -1 * Math.abs(units), ITransactionOperation.SPENT, metadata);
  }
}
