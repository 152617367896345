import { AbstractCollection } from '../../abstract/Collection';
import { AbstractDocument, Identifiable, Timestampable } from '../../abstract/Document';
import { type Supplier, Suppliers } from '../Suppliers';
import type { IFollowUp } from './FollowUps.types';

@Identifiable
@Timestampable
export class FollowUp extends AbstractDocument<IFollowUp> {
  readonly collections = {};

  // readonly translatable = (data: IFollowUp): IFollowUp['_translations'][string] => ({
  //   content: data.content,
  //   title: data.title,
  // });
}

export class FollowUps extends AbstractCollection<FollowUp, IFollowUp> {
  static path = 'follow-ups';

  constructor(document: Supplier) {
    super(document.collection(FollowUps.path), FollowUp);
  }

  get supplier() {
    return Suppliers._.getById(this.reference.parent.id);
  }
}
